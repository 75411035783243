import React, { useCallback, useEffect, useState } from 'react'
import LeagueItem from './LeagueItem'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { FaSave } from 'react-icons/fa';
import { useGetLeagueOrder, useUpdateLeagueOrder } from '../queries';
import { ClipLoader } from 'react-spinners';

export type League = {
  id: number;
  name: string;
}

interface LeagueOrderProps {}

function LeagueOrder({} : LeagueOrderProps) {
  const { data } = useGetLeagueOrder();
  const { mutate: updateLeagueOrder, isPending : isSaving } = useUpdateLeagueOrder();

  const [leagues, setLeagues] = useState([
    {
      id: 1,
      name: "americanfootball_nfl"
    },
    {
      id: 2,
      name: "baseball_mlb"
    },
    {
      id: 3,
      name: "basketball_wnba"
    },
    {
      id: 4,
      name: "icehockey_nhl"
    },
    {
      id: 5,
      name: "basketball_nba"
    },
    {
      id: 6,
      name: "americanfootball_ncaaf"
    },
    {
      id: 7,
      name: "basketball_ncaab"
    },
    {
      id: 8,
      name: "soccer_usa_mls"
    },
    {
      id: 9,
      name: "soccer_italy_serie_a"
    },
    {
      id: 10,
      name: "soccer_epl"
    },
    {
      id: 11,
      name: "soccer_germany_bundesliga"
    },
    {
      id: 12,
      name: "soccer_spain_la_liga"
    },
    {
      id: 13,
      name: "soccer_fifa_world_cup"
    },
    {
      id: 14,
      name: "golf_us_open_winner"
    },
    {
      id: 15,
      name: "golf_the_open_championship_winner"
    },
    {
      id: 16,
      name: "golf_pga_championship_winner"
    },
    {
      id: 17,
      name: "golf_masters_tournament_winner"
    }
  ]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setLeagues((prevCards: League[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as League],
        ],
      }),
    )
  }, [])

  useEffect(() => {
    if(!data)
      return;
    const leagueOrder = JSON.parse(data.value);
    setLeagues(leagueOrder.map((item: string, index: number) => ({
      id: index + 1,
      name: item
    })));
  }, [data])

  const handleSave = () => {
    if(isSaving)
      return;
    const leagueOrder = leagues.map(item => item.name);
    updateLeagueOrder(leagueOrder);
  }

  return (
    <div className='max-w-2xl flex flex-col gap-6'>
      <div className="grid grid-cols-2 gap-4">
        <DndProvider backend={HTML5Backend}>
          { leagues.map((league, index) => 
            <LeagueItem sportKey={league.name} id={league.id} index={index} key={league.id} moveItem={moveCard}/>
          ) }
        </DndProvider>
      </div>
      <div className='flex justify-end'>
        <div className={`${ isSaving ? 'bg-[#89aed8]' : 'bg-[#2382EE]'} text-white text-center px-4 py-2 rounded-lg flex flex-row gap-2 items-center justify-center cursor-pointer`} onClick={handleSave}>
          { isSaving ?
            <>
              <ClipLoader color='#D3D3D3' size={'1.5rem'}/>
              <span>Saving</span>
            </>
            :
            <>
              <FaSave />
              <span>Save</span>
            </>
          }
        </div>
      </div>
    </div>

  )
}

export default LeagueOrder
