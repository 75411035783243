import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import api from "../api/axios"
import { AppConfig } from "../config/AppConfig"

const FETCH_KEYS = {
  FETCH_LEAGUE_ORDER: 'FETCH_LEAGUE_ORDER',
  UPDATE_LEAGUE_ORDER: 'UPDATE_LEAGUE_ORDER'
}

const fetchLeagueOrder = async () => {
  const leagueOrder = await api.get(`${AppConfig.API_BASE_URL}admin/remoteconfig`);
  return leagueOrder.data;
}

export const useGetLeagueOrder = () => {
  return useQuery({
    queryKey: [FETCH_KEYS.FETCH_LEAGUE_ORDER],
    queryFn: fetchLeagueOrder
  })
}

const postLeagueOrder= async (leagueOrder: string[]) => {
  const response = await api.post(`${AppConfig.API_BASE_URL}admin/remoteconfig`, {
    value: JSON.stringify(leagueOrder)
  });
  return response.data;
}

export const useUpdateLeagueOrder = () => {
  const queryClinet = useQueryClient();
  return useMutation({
    mutationFn: postLeagueOrder,
    onSettled: () => queryClinet.invalidateQueries({ queryKey: [FETCH_KEYS.FETCH_LEAGUE_ORDER]}),
    mutationKey: [FETCH_KEYS.UPDATE_LEAGUE_ORDER]
  })
}