import React, { useRef } from 'react'
import AppConstants from '../constants/AppConstants'
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

interface LeagueItemProps {
  sportKey: string;
  id: number;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number
  id: string
  type: string
}

const ItemTypes = {
  CARD: 'card',
}


function LeagueItem({
  sportKey,
  index,
  id,
  moveItem 
}: LeagueItemProps) 
{
  const { league } = AppConstants.SportKeyMapping[sportKey];
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  
  const [{ isDragging, opacity }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.5 : 1
    }),
  })

  drag(drop(ref))

  return (
    <div ref={ref} className="flex items-center space-x-4 rtl:space-x-reverse bg-[#ddd] px-4 py-2 rounded-lg" data-handler-id={handlerId} style={{ opacity }}>
      <div className="w-12 h-12 flex justify-center items-center">
        <img className="max-h-12 max-w-12" src={`/images/leagues/${league}.png`} alt="League image"/>
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-lg font-bold text-gray-900 truncate">
          { league.toUpperCase() }
        </p>
      </div>
    </div>
  )
}

export default LeagueItem
