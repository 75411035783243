const SportKeyMapping : any = {
  "americanfootball_nfl" : {
      "sport" : "football",
      "league" : "nfl"
  },
  "baseball_mlb" : {
      "sport" : "baseball",
      "league" : "mlb"
  },
  "basketball_wnba" : {
      "sport" : "bsktbl",
      "league" : "wnba"
  },
  "icehockey_nhl" : {
      "sport" : "hockey",
      "league" : "nhl"
  },
  "basketball_nba" : {
      "sport" : "bsktbl",
      "league" : "nba"
  },
  "americanfootball_ncaaf" : {
      "sport" : "football",
      "league" : "ncaaf"
  },
  "basketball_ncaab" : {
      "sport" : "bsktbl",
      "league" : "ncaab"
  },
  "soccer_usa_mls": {
      "sport" : "soccer",
      "league" : "mls"
  },
  "soccer_epl": {
      "sport" : "soccer",
      "league" : "epl"
  },
  "soccer_italy_serie_a": {
      "sport" : "soccer",
      "league" : "seriea"
  },
  "soccer_spain_la_liga": {
      "sport" : "soccer",
      "league" : "laliga"
  },
  "soccer_germany_bundesliga": {
      "sport" : "soccer",
      "league" : "bundesliga"
  },
  "soccer_fifa_world_cup": {
      "sport" : "soccer",
      "league" : "worldcup"
  },
  "golf_pga_championship_winner" : {
      "sport" : "golf",
      "league" : "pga"
  },
  "golf_the_open_championship_winner" : {
      "sport" : "golf",
      "league" : "openchampionship"
  },
  "golf_masters_tournament_winner" : {
      "sport" : "golf",
      "league" : "masters"
  },
  "golf_us_open_winner" : {
      "sport" : "golf",
      "league" : "openwinner"
  }
}

export default {
  SportKeyMapping
}