import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';
import api from '../api/axios';
import Sidebar from '../components/Sidebar';
import LeagueOrder from '../components/LeagueOrder';

const Home: React.FC = () => {
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();

  const test = () => {
    api.get('profile').then((res) => {
      console.log(res);
    })
  }
  useEffect(() => {
    if (!isAuthenticated) {
      // navigate('/login');
    }

  }, [isAuthenticated, navigate]);

  return (
    <div className="flex h-screen bg-[#FAFAFA]">
      <Sidebar />
      <div className="flex flex-col grow py-5 px-8 gap-[38px]">
        <LeagueOrder />
      </div>
    </div>
  );
};

export default Home;